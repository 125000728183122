.pay-input,
.pay-input-mobile {
    padding: 16px 16px 16px 16px;
    border-radius: 4px;
    border: 1px solid #e0dcea;
}
.pay-input.number {
    width: 404px;
    height: 56px;
}
.pay-input-mobile.number {
    width: 100%;
    height: 56px;
}
.pay-input.number-modal {
    width: 370px;
    height: 56px;
}
.pay-input.cvv,
.pay-input.expiry {
    width: 100px;
    height: 56px;
}

.pay-outer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 26px;
    transition: 0.2s;
    margin-top: 30px;
    cursor: text;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
    box-shadow: none;
}
.pay-outer-edit-card {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 28px;
}
.pay-input.number-edit-card {
    width: 346px;
}
.pay-input.cvv-edit-card {
    width: 82px;
}
.pay-input.expiry-edit-card {
    width: 123px;
}
